<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="form"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="计费规则">
                <el-select
                  filterable
                  v-model="formInline.chargeTopId"
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.title"
                    :value="value.topId"
                    v-for="value in feeList"
                    :key="value.topId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                v-if="authority.button.query"
                @click="
                  page = 1;
                  searchData();
                "
              >
                查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="authority.button.add"
                @click="addBerth"
              >
                添加
              </el-button>
            </div>
            <div class="col_right"></div>
          </div>
        </el-form>
      </div>

      <!--列表区域-->
      <div class="tableWrapper bgFFF">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="描述"
            width=""
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.descMsg }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Update_time')" width="" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.updatedTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="生效时间" prop="effectiveDateStr" align="center"></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="authority.button.edit || authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="authority.button.edit" command="a"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item v-if="authority.button.delete" command="b"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="updateData()"
            :loading="updateLoading"
            :disabled="tableData.length == 0"
            v-if="authority.button.sync && details.parkAccessType != 3 && 0"
            >同步本地</el-button
          >
        </div>
        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <edit-berth ref="editBerth" @searchData="searchData"></edit-berth>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import editBerth from "./edit";
export default {
  name: "chargeIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {};
      },
    },
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      feeList: [],
      carGroupList: [],
      tableCols: [
        {
          prop: "chargeTopName",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "parkCarGroupName",
          label: this.$t("list.Vehicle_group"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        chargeTopId: "",
      },
      updateLoading: false,
    };
  },
  methods: {
    // 添加
    addBerth() {
      this.$refs.addBerth.open();
    },
    // 查询计费规则
    getFeeList() {
      let url = "/acb/2.0/roadOutEngine/queryFeeList";
      this.$axios
        .post(url, {
          data: {
            pageNum: 1,
            pageSize: 1000
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.feeList = res.value?.list || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 查询车辆组
    getCarGroupList() {
      let url = "/acb/2.0/bacb/msfeeScene/listParkCarGroup";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.carGroupList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.editBerth.getDetail(data);
      }
      if (cmd == "b") {
        this.deleteCharge(data);
      }
    },

    // 删除
    deleteCharge({ roadoutChargeId }) {
      this.$confirm("此操作将删除该计费配置, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/roadOutEngine/deleteRoadoutCharge";
          this.$axios.post(url, {
            data: {
              id: roadoutChargeId
            }
          }).then((res) => {
            if (res.state == 0) {
              this.$message.success("删除成功！");
              this.page = 1;
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    // 搜索
    searchData() {
      this.loading = true;
      let url = "/acb/2.0/roadOutEngine/queryChargeListByParkId";
      this.$axios
        .post(url, {
          data: {
            parkId: this.$route.query.parkId,
            ...this.formInline,
            pageNum: 1,
            pageSize: 1000
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              this.tableData = res.value?.list || [];
            } else {
              this.tableData = [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // 同步本地
    updateData() {
      this.updateLoading = true;
      this.$axios
        .get("/acb/2.0/bacb/msfeeScene/sync", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.getFeeList();
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.updateLoading = false;
          this.page = 1;
          this.getFeeList();
          this.searchData();
        });
    },
    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.getFeeList();
      // this.getCarGroupList()
      this.searchData();
    },
  },
  beforeDestroy() {},
  components: {
    addBerth,
    editBerth,
  },
  created() {
    // console.log('计费规则:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}
</style>
