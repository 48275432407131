<template>
  <div>
    <el-dialog
      title="添加计费规则"
      :visible.sync="dialogVisible"
      width="700px"
      :close-on-click-modal="false"
      custom-class="dialog"
      @close="resetForm"
    >
      <el-form ref="form" :model="form" label-width="140px" :rules="rules" style="padding: 0">
        <el-form-item label="车辆组" prop="parkCarGroupId">
          <el-select v-model="form.parkCarGroupId" placeholder="请选择" multiple collapse-tags>
            <el-option
              :label="value.parkCarGroupName"
              :value="value.parkCarGroupId"
              v-for="value in carGroupList"
              :key="value.parkCarGroupId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="计费规则" prop="chargeTopId">
          <el-select v-model="form.chargeTopId" placeholder="请选择">
            <el-option
              :label="value.title"
              :value="value.topId"
              v-for="value in feeList"
              :key="value.topId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
        <el-button @click="resetForm">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        parkCarGroupId: [],
        chargeTopId: "",
      },
      feeList: [],
      carGroupList: [],
      rules: {
        parkCarGroupId: {
          type: "array",
          required: true,
          message: "请选择车辆组",
          trigger: "change",
        },
        chargeTopId: {
          required: true,
          message: "请选择计费规则",
          trigger: "change",
        },
      },
      saveLoading: false,
    };
  },
  methods: {
    // 打开窗口
    open() {
      this.dialogVisible = true;
      this.form.parkCarGroupId = [];
      this.form.chargeTopId = "";
      this.getCarGroupList();
      this.getFeeList();
    },

    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let prams = {};
          prams.parkCarGroupId = this.form.parkCarGroupId.join(",");
          prams.chargeTopId = this.form.chargeTopId;

          // console.log('提交分析:' + JSON.stringify(parms))
          this.saveLoading = true;
          let url = "/acb/2.0/roadOutEngine/bindRoadOutCharge";
          this.$axios
            .post(url, {
              data: {
                parkId: this.$route.query.parkId,
                ...prams,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                setTimeout(() => {
                  this.saveLoading = false;
                  this.dialogVisible = false;
                  this.$emit("searchData");
                }, 2000);
              } else {
                this.saveLoading = false;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 查询车辆组
    getCarGroupList() {
      // console.log('选中值:' + val)
      this.form.parkCarGroupId = [];
      this.carGroupList = [];
      this.$refs.form?.clearValidate("parkCarGroupId");
      let url = "/acb/2.0/bacb/msfeeScene/listParkCarGroup";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.carGroupList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 查询计费规则
    getFeeList() {
      // console.log(2)
      this.feeList = [];
      let url = "/acb/2.0/roadOutEngine/queryFeeList";
      this.$axios
        .post(url, {
          data: {
            pageNum: 1,
            pageSize: 1000
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.feeList = res.value?.list || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
  },
  created() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
